import React from "react";
import { Section } from "../components/next/content";
import { Footer as FooterContainer, FooterItem, FooterLink, FooterLinkNative, FooterSection } from "../components/next/footer";

export function Footer(): JSX.Element {
    return (
        <Section>
            <FooterContainer>
                <FooterSection>
                    <FooterItem>
                        <FooterLink to="/imprint">IMPRINT</FooterLink>
                    </FooterItem>
                    <FooterItem>
                        <FooterLink to="/privacy-policy">PRIVACY POLICY</FooterLink>
                    </FooterItem>
                    <FooterItem>
                        <FooterLinkNative href="/Presskit.zip" target="_blank">PRESSKIT</FooterLinkNative>
                    </FooterItem>
                </FooterSection>
            </FooterContainer>
        </Section>
    );
}
