import * as React from "react"
import { Footer } from "../components/footer";
import { Inset, PageLayout } from "../components/layout";
import { H1, H2, Link, Paragraph } from "../components/typography2";

const NotFoundPage = () => {
    return (<PageLayout>
        <Inset>
            <H1>404</H1>
            <H2>That page does not exist!</H2>
            <Paragraph>Sorry, the page you were looking for could not be found.</Paragraph>
            <Paragraph><Link to="/">Click here</Link> to go back to our home page.</Paragraph>
        </Inset>
        <Footer />
    </PageLayout>);
}

export default NotFoundPage;
