import styled from "@emotion/styled";

export const PageLayout = styled.div`
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    font-family: Roboto;
    color: #000;
`;

export const Inset = styled.div`
    padding: 2rem;
`;

export const FlexSpacer = styled.div`
    flex: 1;
`;
